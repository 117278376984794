import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import parse from "html-react-parser"

const RendezvenyekPage = () => {

  const pageQuery = useStaticQuery(graphql`
      query Rendezvenyek {
          markdownRemark(frontmatter: {pagename: {eq: "rendezvenyek"}}) {
              frontmatter {
                  title
              }
              html
          }
      }
  `)

  const { html, frontmatter } = pageQuery?.markdownRemark
  const { title, gallery } = frontmatter


  return <>
    <Layout>
      <Seo title={title} />
      {parse(html || "")}
    </Layout>
  </>
}

export default RendezvenyekPage
